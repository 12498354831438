import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Services.css';

const Services = ({ handlePlatformSelect, servicesData, platformLogos }) => {
  const platforms = Object.keys(servicesData);

  return (
    <div className="service-highlights py-5">
      <Container id="services">
        <h2 className="text-center mb-4">Our Premium Services</h2>
        <Row className="justify-content-center">
          {platforms.map((platform) => (
            <Col 
              key={platform} 
              xs={12} 
              md={6} 
              lg={4} 
              className="mb-3"
            >
              <Card 
                className="service-card text-center" 
                onClick={() => handlePlatformSelect(platform)}
              >
                <Card.Img 
                  variant="top" 
                  src={platformLogos[platform]} 
                  alt={platform} 
                  className="platform-icon-small mx-auto mt-3" 
                />
                <Card.Body>
                  <Card.Title>{platform}</Card.Title>
                  <Card.Text>
                    {/* Show the minimum price for the first service */}
                    {servicesData[platform] && (
  <>
    Starting at ${Object.keys(servicesData[platform])
      .reduce((minPrice, serviceKey) => {
        const service = servicesData[platform][serviceKey];
        const servicePrice = service.rate * service.min;
        return Math.min(minPrice, servicePrice);
      }, Infinity).toFixed(2)}
  </>
)}

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Services;
