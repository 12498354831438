import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import './Testimonials.css';

const Testimonials = ({handlePlatformSelect, platform}) => {
  const testimonials = [
    { quote: "Our engagement skyrocketed! I keep coming back for more.", name: "Alexandra D., Brand Owner" },
    { quote: "Real followers, real interactions—genuine growth!", name: "Michael H., Influencer" },
    { quote: "The best investment for brand visibility we've made.", name: "Sarah L., Marketing Specialist" },
    { quote: "Their service is phenomenal! We've seen a steady growth in engagement.", name: "John P., Startup Founder" },
    { quote: "Highly recommend for anyone looking to grow real social engagement!", name: "Linda M., Blogger" },
    { quote: "This was the boost our brand needed. Authentic results!", name: "Emily R., Small Business Owner" },
    { quote: "The quality of followers and interaction exceeded our expectations.", name: "Chris T., Musician" },
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 4000); // Change testimonial every 4 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [testimonials.length]);

  return (
    <div className="testimonials-section py-5">
      <Container>
        <Row className="justify-content-center text-center">
          <Col md={8}>
            <h2 className="mb-4">What Our Clients Say</h2>
            <div className="testimonial-slider">
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className={`testimonial ${index === currentTestimonial ? 'active' : ''}`}
                >
                  <p className="testimonial-quote">
                    <strong>“{testimonial.quote}”</strong>
                  </p>
                  <p className="testimonial-name">- {testimonial.name}</p>
                  <div className="star-rating mb-3">
                    {[...Array(5)].map((_, i) => (
                      <FaStar key={i} color="#FFD700" size={28} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="call-to-action mt-4">
              <button className="cta-btn" onClick={()=>handlePlatformSelect(platform)}>Boost Your Engagement Today</button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Testimonials;
