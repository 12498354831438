// AdminChat.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminChat.css';

function AdminChat({mongoURI}) {
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [message, setMessage] = useState("");
  const [threadName, setThreadName] = useState("");
  const [loading, setLoading] = useState(false);
  const apiUrl = `${mongoURI}adminMessages`;
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    fetchThreads();
  }, []);

  const fetchThreads = async () => {
    try {
      const response = await axios.post(apiUrl, { action: 'fetch' , token });
      setThreads(response.data.threads);
    } catch (error) {
      console.error('Failed to fetch threads', error);
    }
  };

  const handleThreadClick = (thread) => {
    setSelectedThread(thread);
    setThreadName(thread.name || "");
  };

  const handleStatusChange = async (threadID) => {
    try {
      await axios.post(apiUrl, {
        action: 'updateStatus',
        threadID,
        status: 'withAdmin',
        token
      });
      fetchThreads(); // Refresh the threads
    } catch (error) {
      console.error('Failed to update status', error);
    }
  };

  const handleSendMessage = async () => {
    if (!message) return;
    setLoading(true);
    try {
      await axios.post(apiUrl, {
        action: 'reply',
        threadID: selectedThread.threadID,
        message,
        token
      });
      setMessage("");
      fetchThreads(); // Refresh messages after sending
    } catch (error) {
      console.error('Failed to send message', error);
    }
    setLoading(false);
  };

  const handleSaveThreadName = async (threadID) => {
    try {
      await axios.post(apiUrl, {
        action: 'saveName',
        threadID,
        name: threadName,
        token
      });
      fetchThreads(); // Refresh threads
    } catch (error) {
      console.error('Failed to save thread name', error);
    }
  };

  return (
    <div className="admin-chat">
      <div className="threads-list">
        {threads.map((thread) => (
          <div
            key={thread.threadID}
            className={`thread-item ${selectedThread && selectedThread.threadID === thread.threadID ? 'selected' : ''}`}
            onClick={() => handleThreadClick(thread)}
          >
            <div className="thread-name">{thread.name || 'Unnamed Thread'}</div>
            <div className="thread-status">Status: {thread.status}</div>
          </div>
        ))}
      </div>

      {selectedThread && (
        <div className="chat-box">
          <div className="chat-header">
            <input
              type="text"
              value={threadName}
              onChange={(e) => setThreadName(e.target.value)}
              placeholder="Enter Thread Name"
            />
            <button onClick={() => handleSaveThreadName(selectedThread.threadID)}>
              Save Name
            </button>
            {selectedThread.status !== 'withAdmin' && (
              <button onClick={() => handleStatusChange(selectedThread.threadID)}>
                Set to Admin
              </button>
            )}
          </div>

          <div className="chat-messages">
            {selectedThread.messages.map((msg, index) => (
              <div key={index} className={`message ${msg.role}`}>
                <div>{msg.content}</div>
              </div>
            ))}
          </div>

          <div className="chat-input">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
            />
            <button onClick={handleSendMessage} disabled={loading}>
              {loading ? 'Sending...' : 'Send'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminChat;
