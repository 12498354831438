import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Container, FloatingLabel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';

const Login = ({ setAuthToken, mongoURI }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    setError('');
    try {
      const response = await axios.post(`${mongoURI}login`, { username, password });
      setAuthToken(response.data.token);
      localStorage.setItem('authToken', response.data.token);
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Invalid username or password');
    }
  };

  return (
    <Container className="login-container">
      <h2 className="text-center">Login</h2>
      {error && <Alert variant="danger" className="text-center">{error}</Alert>}
      <Form>
        <FloatingLabel
          controlId="floatingUsername"
          label="Username"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingPassword"
          label="Password"
          className="mb-3"
        >
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FloatingLabel>

        <Button
          variant="primary"
          type="button"
          className="w-100"
          onClick={handleLogin}
        >
          Login
        </Button>
      </Form>
    </Container>
  );
};

export default Login;
