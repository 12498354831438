import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './HighlightsSection.css';

const HighlightsSection = () => {
  return (
    <div className="highlights-section mt-5">
      <Row className="text-center">
        <Col xs={12} sm={6} md={3} className="highlight-item">
          <div className="icon safe-secure-icon"></div>
          <h4>SAFE & SECURE</h4>
          <p>Our state-of-the-art encryption ensures your data is always protected.</p>
        </Col>
        <Col xs={12} sm={6} md={3} className="highlight-item">
          <div className="icon satisfaction-icon"></div>
          <h4>100% SATISFACTION</h4>
          <p>See your influence grow with our guaranteed social proof services.</p>
        </Col>
        <Col xs={12} sm={6} md={3} className="highlight-item">
          <div className="icon support-icon"></div>
          <h4>24/7 SUPPORT</h4>
          <p>Our dedicated team is available around the clock to assist you.</p>
        </Col>
        <Col xs={12} sm={6} md={3} className="highlight-item">
          <div className="icon money-back-icon"></div>
          <h4>MONEY BACK GUARANTEE</h4>
          <p>Not satisfied? Get a full refund, no questions asked.</p>
        </Col>
      </Row>
    </div>
  );
};

export default HighlightsSection;
