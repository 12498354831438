import React, { useEffect, useState } from 'react';
import './FooterIcon.css';
import chatIcon from '../assets/chat-icon.png'; // You can replace this with faqIcon if you prefer

const FooterIcon = ({setShowFaqModal}) => {
  const [isChatModalOpen, setChatModalOpen] = useState(false);

  useEffect(() => {
    setShowFaqModal(isChatModalOpen);
  }, [isChatModalOpen]);

  return (
    <div className="footer-icon">
      <img src={chatIcon} alt="Chat with us" className="icon" onClick={()=>setShowFaqModal(true)} />

      {/* {isChatModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleChatModal}>&times;</span>
            <p>Chat with us content goes here...</p>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default FooterIcon;
