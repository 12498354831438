import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form, FloatingLabel, Alert, Spinner } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import './OrderConfirmationModal.css';
import axios from 'axios';

const OrderConfirmationModal = ({ servicesData, convertTime, setShowFaqModal, currencySymbols, showOrderConfirmation, setShowOrderConfirmation, lastSuccessfulOrderDetails, mongoURI }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const inputRef = useRef(null);
  const [afterClose, setAfterClose] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    if(showWarning) {
      inputRef.current.focus();
      inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [showWarning]);

  const handleSendReceipt = async () => {
    setEmailSending(true);
    setEmailError('');
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      setEmailSending(false);
      return;
    }
    try {
      const response = await axios.post(`${mongoURI}sendConfirmationEmail`, {
        orderId: lastSuccessfulOrderDetails.id,
        email,
      });

      if (response.status === 200) {
        setEmailSent(true);
      } else {
        setEmailError('Failed to send receipt, please try again.');
      }
    } catch (error) {
      setEmailError('Error sending receipt, please try again.');
    } finally {
      setEmailSending(false);
    }
  };

  const handleClose = (whatTodo) => {
    if (emailSent) {
      setShowOrderConfirmation(false);
      if(whatTodo === 'faq' || afterClose === 'faq') {
        setShowFaqModal(true);
      }
    } else {
      setShowWarning(true)
      inputRef.current.focus();
      inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleSkipTracking = () => {
    setShowOrderConfirmation(false);
    if (afterClose === 'faq') {
      setShowFaqModal(true);
    }
  };

  return (
    <Modal show={showOrderConfirmation} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">Order Confirmed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="confirmation-content text-center">
          <FaCheckCircle size={80} color="#28a745" />
          <h3 className="mt-3">Thank you! Your order has been placed.</h3>
          {/* Average Processing time */}
          {servicesData[lastSuccessfulOrderDetails.platform][lastSuccessfulOrderDetails.service]?.average_time ? <p className="mt-3">Estimated Time: { convertTime(servicesData[lastSuccessfulOrderDetails.platform][lastSuccessfulOrderDetails.service].average_time)
            }</p> : ''}

          <div className="order-details mt-4">
            <p><strong>Platform:</strong> {lastSuccessfulOrderDetails.platform}</p>
            <p><strong>Service:</strong> {lastSuccessfulOrderDetails.service}</p>
            <p><strong>Quantity:</strong> {lastSuccessfulOrderDetails.quantity}</p>
            <p><strong>Username/Post URL:</strong> {lastSuccessfulOrderDetails.usernameOrLink}</p>
            <p><strong>Amount Paid:</strong> {(currencySymbols[lastSuccessfulOrderDetails.currency] || lastSuccessfulOrderDetails.currency)}{lastSuccessfulOrderDetails.priceAfterDiscount}</p>
          </div>

          {showWarning ? (
            <Alert variant="warning" className="mt-3">
              <p>Skip Order Tracking, Receipt and Updates?</p>
              <FloatingLabel
                controlId="floatingInput"
                label="Enter your email"
                className="mb-2"
              >
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError('');
                    setEmailSent(false);
                  }}
                  className={`email-input ${emailError ? 'is-invalid' : ''}`}
                  ref={inputRef}
                />
                {emailError && <div className="invalid-feedback">{emailError}</div>}
              </FloatingLabel>
              <div className="d-flex justify-content-between">
                <Button variant="danger" className="skip-tracking-button m-1" style={{ width: '100%' }} onClick={handleSkipTracking}>
                  Skip
                </Button>
                <Button
                  variant="success"
                  className="email-button m-1"
                  onClick={handleSendReceipt}
                  style={{ width: '100%' }}
                  disabled={emailSent || emailSending}
                >
                  {emailSending ? <Spinner as="span" animation="border" size="sm" /> : emailSent ? 'Receipt Sent' : 'Send Receipt'}
                </Button>
              </div>
            </Alert>
          ) : (
            <div className="email-receipt mt-3">
              <Form.Group className="email-form-group d-flex align-items-center mb-3">
                <FloatingLabel controlId="floatingInput" label="Email address">
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError('');
                      setEmailSent(false);
                    }}
                    className={`${emailError ? 'is-invalid' : ''}`}
                    ref={inputRef}
                  />
                  {emailError && <div className="invalid-feedback">{emailError}</div>}
                </FloatingLabel>
                <Button
                  variant="primary"
                  className="email-button m-2"
                  onClick={handleSendReceipt}
                  disabled={emailSent || emailSending}
                >
                  {emailSending ? <Spinner as="span" animation="border" size="sm" /> : emailSent ? 'Receipt Sent' : 'Send Receipt'}
                </Button>
              </Form.Group>
            </div>
          )}

          <div className="mt-2 d-flex justify-content-between">
            {!showWarning && (
              <>
                <Button variant="info" className="me-2" onClick={() => { setAfterClose('faq'); handleClose('faq'); }}>
                  Have Questions?
                </Button>
                <Button variant="success" onClick={handleClose}>
                  Place New Order
                </Button>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OrderConfirmationModal;
