import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import './Hero.css';
import Payment from './Payment';
import timeIcon from '../assets/time.png';

const Hero = ({ convertTime, api, country, currency, currencySymbols, couponIcon, platform, onPlatformChange, servicesData, platformLogos, serviceIcon, urlImg, quantityImg, setLastSuccessfulOrderDetails, setShowOrderConfirmation, currencyConversionRates, mongoURI }) => {
  const [service, setService] = useState('');
  const [quantity, setQuantity] = useState('');
  const [usernameOrLink, setUsernameOrLink] = useState('');
  const [convertedPrice, setConvertedPrice] = useState({});
  const [showPayment, setShowPayment] = useState(false);
  const [orderPlaceLoading, setOrderPlaceLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [order, setOrder] = useState();
  const [showReferralInput, setShowReferralInput] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [referralLabel, setReferralLabel] = useState({ text: 'Referral Code (Optional)', color: 'black' });
  const [quantityValid, setQuantityValid] = useState(false);

  const serviceRef = useRef(null);
  const urlRef = useRef(null);
  const quantityRef = useRef(null);

  useEffect(() => {
    setService('');
    platform && setTimeout(() => {
      const serviceSelect = document.getElementById('serviceSelect');
      if (serviceSelect) {
        serviceSelect.focus();
      }
    }, 0);
  }, [platform]);

  useEffect(() => {
    if (service) {
      let maxQuantity = servicesData[platform][service]?.max;
      let minQuantity = servicesData[platform][service]?.min;
      let reasonableQuantity = Math.ceil((maxQuantity - minQuantity) / 100);
      setQuantity(reasonableQuantity < minQuantity ? minQuantity : reasonableQuantity);
      quantityRef.current.focus();
      quantityRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else {
      setQuantity('');
    }
  }, [service]);

  useEffect(() => {
    setConvertedPrice({});
    updatePrices(service, quantity);
    if (service && quantity) {
      let min = servicesData[platform][service]?.min;
      let max = servicesData[platform][service]?.max;
      if (quantity && quantity >= min && quantity <= max) {
        ;
        setQuantityValid(true);
      }
      else {
        setQuantityValid(false);
      }
    }
    else {
      setQuantityValid(false);
    }
  }, [quantity]);

  useEffect(() => {
    handleReferralCodeChange();
  }, [referralCode]);

  useEffect(() => {
    updatePrices();
  }, [discount]);

  const updatePrices = () => {
    setShowPayment(false);
    if (!quantity || !servicesData[platform][service]) {
      setConvertedPrice({});
      return;
    }
    let originalPrice = ((servicesData[platform][service]?.rate * quantity));
    if (discount) {
      originalPrice = originalPrice - (originalPrice * discount * 0.01);
    }
    const updatedPrices = {};
    for (const [key, rate] of Object.entries(currencyConversionRates)) {
      updatedPrices[key] = originalPrice > 0 ? (originalPrice * rate).toFixed(2) : '';
    }
    setConvertedPrice(updatedPrices);
  };

  const handleUrlChange = (e) => {
    setUsernameOrLink(e.target.value);
    setShowPayment(false); // Hide payment on URL change
  };

  const handleReferralCodeChange = async () => {
    if (!referralCode) {
      setDiscount(0);
      setReferralLabel({ text: 'Referral Code (Optional)', color: 'black' });
      return;
    }
    if (referralCode.length === 3) {
      setReferralLabel({ text: 'Validating referral code...', color: 'blue' });
      try {
        const response = await axios.post(`${mongoURI}validateReferralCode`, { referralCode });
        if (response.data.valid) {
          setDiscount(response.data.discount);
          setReferralLabel({ text: `${response.data.discount}% discount applied!`, color: 'green' });
        } else {
          setDiscount(0);
          setReferralLabel({ text: 'Referral Code Not Valid', color: 'red' });
        }
      } catch (error) {
        console.error('Error validating referral code:', error);
        setDiscount(0);
      }
    }
    else {
      setReferralLabel({ text: 'Referral Code Not Valid', color: 'red' });
      setDiscount(0);
    }
  };

  const handleReferralLinkClick = (e) => {
    e.preventDefault();
    setShowReferralInput(true);
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    setOrderPlaceLoading(true);
    if (platform && service && quantity && usernameOrLink) {
      try {
        const orderData = {
          platform,
          service,
          serviceCode: servicesData[platform][service].code,
          quantity,
          usernameOrLink,
          currency,
          priceAfterDiscount: `${convertedPrice[currency]}`
        };
        if (discount > 0) {
          orderData.referralCode = referralCode;
          orderData.discount = discount;
        }
        // Send order data to backend
        const res = await axios.post(`${mongoURI}createOrder`, orderData);
        orderData.id = res.data;
        setOrder(orderData);
        // Calculate total amount in cents (Stripe requires amounts in cents)
        const calculatedAmount = convertedPrice[currency] * 100;
        setAmount(calculatedAmount);
        setOrderPlaceLoading(false);
        // Show payment screen with calculated amount
        setShowPayment(true);
        if (!discount) {
          setShowReferralInput(false);
        }
      } catch (error) {
        console.error('Error creating order:', error);
        alert('There was an error creating your order. Please try again.');
        setOrderPlaceLoading(false);
      }
    } else {
      alert('Please fill in all required fields.');
      setOrderPlaceLoading(false);
    }
  };

  const handlePaymentSuccess = async (paymentIntent) => {
    setLastSuccessfulOrderDetails(order);
    setShowPayment(false);
    setShowOrderConfirmation(true);
    setService('');
    try {
      await axios.post(`${mongoURI}updateOrderStatusForNewOrder`, {
        paymentIntentId: paymentIntent.id,
        orderId: order.id
      });
    } catch (error) {
      console.error(error);
      alert("Error updating order status. Please contact support.");
    }
    if(!order.serviceCode.includes('P_')) {
    try {
      await axios.post(api, {
        task: 'placeOrder',
        order,
        paymentIntentId: paymentIntent.id,
      });
    }
    catch (error) {
      console.error(error);
    }
  }
  };

  return (
    <div className="hero-section" id="hero">
      <Container>
        <Row>
          <Col md={8} className="mx-auto">
            <h1>Boost Your Social Media Presence</h1>
            <p>Real growth with highest quality engagement. Order Now!<br /></p>
            <Form onSubmit={handlePlaceOrder}>

              {/* Platform Selection */}
              <div className="d-flex align-items-center mb-3">
                {platform && (
                  <img src={platformLogos[platform]} alt={platform} className="platform-icon-small me-2" />
                )}
                <FloatingLabel controlId="platformSelect" label="Select Platform" className="flex-grow-1">
                  <Form.Select value={platform || ''} onChange={(e) => onPlatformChange(e.target.value)}>
                    {!servicesData && <option value="">Loading...</option>}
                    {servicesData && Object.keys(servicesData).map((platformKey) => (
                      <option key={platformKey} value={platformKey}>
                        {platformKey}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              </div>

              {/* Service Selection */}
              {platform && (
                <div className={` ${servicesData[platform] && servicesData[platform][service]?.description && 'selector-with-description'}`} >
                  <div className={`d-flex align-items-center ${servicesData[platform] && !servicesData[platform][service]?.description && 'mb-3'}`}>
                    <img src={serviceIcon} alt={service} className="platform-icon-small me-2" />
                    <FloatingLabel controlId="serviceSelect" label={`${!service ? "Select " : ''}Service`} className="flex-grow-1">
                      <Form.Select
                        value={service || ''}
                        onChange={(e) => setService(e.target.value || '')}
                        ref={serviceRef}
                      >
                        {!servicesData && <option value="">Loading...</option>}
                        {!service && <option value="">Select a Service</option>}
                        {Object.keys(servicesData[platform] || {}).map((serviceKey) => (
                          <option key={serviceKey} value={serviceKey}>
                            {serviceKey}
                          </option>
                        ))}
                      </Form.Select>
                      {/* Description */}
                      {service && servicesData[platform][service]?.description && (
                        <div className='d-flex align-items-center mb-3'>
                          <div className="service-description-container">
                            {servicesData[platform][service].description}
                          </div>
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                </div>
              )}

              {/* Quantity Input */}
              {service && (
                <div className="d-flex align-items-center mb-3">
                  <img src={quantityImg} alt="Quantity" className="platform-icon-small me-2" />
                  <FloatingLabel
                    controlId="quantityInput"
                    label={`${!quantity ? 'Enter ' : ""}Quantity ${(!quantityValid && quantity) ? `(${servicesData[platform][service]?.min} - ${servicesData[platform][service]?.max})` : ''}`}
                    className={`flex-grow-1 ${(!quantityValid && quantity) ? 'error' : ''}`}
                  >
                    <Form.Control
                      type="number"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      ref={quantityRef}
                      required
                    />
                  </FloatingLabel>

                  {/* Tooltip for Average Time */}
                  {servicesData[platform][service]?.average_time > 0 && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="averageTimeTooltip">
                          Average Time (based on last 10 completed orders): {servicesData[platform][service]?.average_time ? convertTime(servicesData[platform][service].average_time) : 'N/A'}
                        </Tooltip>
                      }
                    >
                      <span className="ms-2">
                        <img src={timeIcon} className="platform-icon-small me-2" style={{ cursor: 'pointer' }} />
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
              )}

              {/* URL Input */}
              {quantityValid && (
                <div className="d-flex align-items-center mb-3">
                  <img src={urlImg} alt="Username/Link" className="platform-icon-small me-2" />
                  <FloatingLabel
                    controlId="urlInput"
                    label={servicesData[platform][service]?.link || ''}
                    className="flex-grow-1 blackText floating-label-input"
                  >
                    <Form.Control
                      type="text"
                      placeholder={servicesData[platform][service]?.link}
                      value={usernameOrLink}
                      onChange={handleUrlChange}
                      ref={urlRef}
                      required
                    />
                  </FloatingLabel>

                </div>
              )}

              {/* Referral Code Link */}
              {!showReferralInput && quantityValid && !showPayment && usernameOrLink && (
                <div className="mb-2 text-center">
                  <a href="#" className="referral-link small" onClick={handleReferralLinkClick}>
                    Have a referral code?
                  </a>
                </div>
              )}

              {/* Referral Code Input */}
              {showReferralInput && quantityValid && (
                <div className="d-flex align-items-center mb-3">
                  <img src={couponIcon} alt="Referral Code" className="platform-icon-small me-2" />
                  <FloatingLabel controlId="referralCodeInput" label={referralLabel.text} className={`flex-grow-1 ${referralLabel.color}Text`}>
                    <Form.Control
                      type="text"
                      placeholder={referralLabel.text}
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                    />
                  </FloatingLabel>
                </div>
              )}

              {/* Place Order Button */}
              {quantityValid && !showPayment && (
                <div className="d-flex justify-content-center mb-3">
                  <button
                    type="submit"
                    className="btn submitButton"
                    disabled={orderPlaceLoading}
                  >{orderPlaceLoading ?
                    'Processing Order...' :
                    `Place Order (${currencySymbols[currency] || currency}${Number(convertedPrice[currency]).toLocaleString()})`}
                  </button>
                </div>
              )}

              {showPayment && amount && (
                <Payment
                currencySymbols={currencySymbols}
                  amount={amount}
                  currency={currency}
                  onPaymentSuccess={handlePaymentSuccess}
                  onClose={() => setShowPayment(false)}
                  mongoURI={mongoURI}
                  country={country}
                />
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
