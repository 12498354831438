import React, { useState, useEffect } from 'react';
import { CardElement, PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './Payment.css';

const Payment = ({ amount, email, currency, country, onPaymentSuccess, mongoURI, currencySymbols }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCardValid, setIsCardValid] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const cardElementOptions = {
    style: {
      base: {
        // color: '#32325d', // Dark grey color for text
        fontSize: '16px', // Modern, readable font size
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif', // Modern fonts
        fontSmoothing: 'antialiased', // Smooth font rendering
        padding: '10px 12px', // Add padding to make the field more spacious
        borderRadius: '8px', // Rounded corners for a modern look
        border: '2px solid #ccc', // Light border to enhance the form field appearance
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
      },
      focus: {
        color: '#007bff', // Blue color when focused
        borderColor: '#007bff', // Blue border color when the field is focused
      },
    },
  };
  

  const handleCardChange = (event) => {
    setError(event.error ? event.error.message : '');
    setIsCardValid(event.complete);
  };

  // Payment Request API for Google Pay and Apple Pay
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country,
        currency: currency.toLowerCase(),
        total: {
          label: 'Total',
          amount: Math.round(amount),
        },
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);  // Show button if GPay/ApplePay is supported
        }
      });

      pr.on('paymentmethod', async (ev) => {
        try {
          // Send paymentMethodId to backend
          const { data: { clientSecret } } = await axios.post(`${mongoURI}createPaymentIntent`, {
            amount: Math.round(amount),
            currency,
            paymentMethodId: ev.paymentMethod.id,  // Pass paymentMethod ID to backend
            email: email || null,  // Conditionally send email if provided in form
          });

          // Confirm the Payment Intent
          const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: ev.paymentMethod.id,
          });

          if (stripeError) {
            ev.complete('fail');
            setError(stripeError.message);
            setLoading(false);
          } else {
            ev.complete('success');
            onPaymentSuccess(paymentIntent);
          }
        } catch (err) {
          setError('Payment failed. Please try again.');
          ev.complete('fail');
          setLoading(false);
        }
      });
    }
  }, [stripe, amount, currency, onPaymentSuccess, mongoURI, email]);

  // Handle Card Payment Submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    if (!isCardValid) {
      setError('Please complete your card details correctly.');
      return;
    }

    setLoading(true);

    try {
      // Create payment method using CardElement
      const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          email: email || null,  // Send email with card payment if provided
        },
      });

      if (paymentMethodError) {
        setError(paymentMethodError.message);
        setLoading(false);
        return;
      }

      // Send paymentMethodId to backend
      const { data: { clientSecret } } = await axios.post(`${mongoURI}createPaymentIntent`, {
        amount: Math.round(amount),
        currency,
        paymentMethodId: paymentMethod.id,  // Pass paymentMethod ID to backend
        email: email || null,  // Conditionally include email if provided
      });

      // Confirm the payment
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (stripeError) {
        setError(stripeError.message);
        setLoading(false);
      } else {
        onPaymentSuccess(paymentIntent);
      }
    } catch (err) {
      setError('Payment failed. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="payment-section">
      {paymentRequest && (
        <div className="payment-request-button mb-2">
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      )}
      <div className="card-element-container">
        <CardElement options={cardElementOptions} onChange={handleCardChange} />
      </div>
      {error && <div className="error-message">{error}</div>}
      <button
        className="btn submitButton"
        onClick={handleSubmit}
        disabled={error || loading}
      >
        {loading ? 'Processing Payment..' : `Pay ${currencySymbols[currency] || (currency.toUpperCase() + ' ')}${(amount / 100).toFixed(2)}`}
      </button>
    </div>
  );
};

export default Payment;
