import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Card, Modal, Button, Container } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './AdminOrders.css';

const AdminOrders = ({ mongoURI }) => {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState({
    showCompleted: false,
    showCancelled: false,
    showPending: false,  // Added filter for Payment Pending
    showDate: false,
    showPrice: false
  });
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [newStatus, setNewStatus] = useState('');

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.post(`${mongoURI}getAllOrders`, { token });
      if (response.data.status === 401) {
        localStorage.removeItem('authToken');
        window.location.reload();
      }
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleStatusChange = (order, status) => {
    setSelectedOrder(order);
    setNewStatus(status);
    setShowConfirm(true);
  };

  const confirmStatusChange = async () => {
    if (selectedOrder) {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.post(`${mongoURI}updateOrderByAdmin`, {
          orderId: selectedOrder._id,
          newStatus,
          token
        });
        if (response.data.status === 401) {
          localStorage.removeItem('authToken');
          window.location.reload();
        }
        fetchOrders();  // Refresh orders list after update
      } catch (error) {
        console.error('Error updating order status:', error);
      } finally {
        setShowConfirm(false);
        setSelectedOrder(null);
        setNewStatus('');
      }
    }
  };

  const filteredOrders = orders.filter(order => {
    return (
      (!filter.showPending ? order.status !== 'Payment Pending' : true) &&
      (!filter.showCompleted ? order.status !== 'Completed' : true) &&
      (!filter.showCancelled ? order.status !== 'Cancelled' : true)
    );
  });

  return (
    <Container>
      <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Order {newStatus}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to change the status of the following order?</p>
          <ul>
            <li><strong>Platform:</strong> {selectedOrder?.platform}</li>
            <li><strong>Service:</strong> {selectedOrder?.service}</li>
            <li><strong>Quantity:</strong> {selectedOrder?.quantity}</li>
            <li><strong>Username/Link:</strong> {selectedOrder?.usernameOrLink}</li>
            <li><strong>Current Status:</strong> {selectedOrder?.status}</li>
            <li><strong>New Status:</strong> {newStatus}</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirm(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmStatusChange}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Card className="admin-orders-card">
        <Card.Header className="sticky-header">
          <h2 className="heading">Orders Management</h2>
          <div className="filter-options">
            <label>
              <input
                type="checkbox"
                checked={filter.showCompleted}
                onChange={(e) => setFilter({ ...filter, showCompleted: e.target.checked })}
              />
              Completed
            </label>
            <label>
              <input
                type="checkbox"
                checked={filter.showCancelled}
                onChange={(e) => setFilter({ ...filter, showCancelled: e.target.checked })}
              />
              Cancelled
            </label>
            <label>
              <input
                type="checkbox"
                checked={filter.showPending}
                onChange={(e) => setFilter({ ...filter, showPending: e.target.checked })}
              />
              Payment Pending
            </label>
            <label>
              <input
                type="checkbox"
                checked={filter.showDate}
                onChange={(e) => setFilter({ ...filter, showDate: e.target.checked })}
              />
              Date
            </label>
            <label>
              <input
                type="checkbox"
                checked={filter.showPrice}
                onChange={(e) => setFilter({ ...filter, showPrice: e.target.checked })}
              />
              Price
            </label>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="table-container">
            <Table hover responsive className="styled-table">
              <thead>
                <tr>
                  <th>Status</th>
                  {filter.showDate && <th>Date</th>}
                  <th>Platform</th>
                  <th>Service</th>
                  <th>Link</th>
                  {filter.showPrice && <th>Price</th>}
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((order) => (
                  <tr key={order._id} className={`order-row ${getRowClass(order.status)}`}>
                    <td className="action-icons">
                      {(order.status !== 'Completed' && order.status !== 'Cancelled') && (
                        <>
                          <FaCheckCircle
                            className="complete-icon"
                            onClick={() => handleStatusChange(order, 'Completed')}
                          />
                          <FaTimesCircle
                            className="cancel-icon"
                            onClick={() => handleStatusChange(order, 'Cancelled')}
                          />
                        </>
                      )}
                      {order.status !== 'In progress' && <span>{order.status}</span>}
                    </td>
                    {filter.showDate && <td>{new Date(order.date).toLocaleString()}</td>}
                    <td>{order.platform}</td>
                    <td>{order.quantity} {order.service}</td>
                    <td>{order.usernameOrLink}</td>
                    {filter.showPrice && <td>{`${order.currency} ${order.priceAfterDiscount}`}</td>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );

  function getRowClass(status) {
    switch (status) {
      case 'Completed':
        return 'row-completed';
      case 'In progress':
        return 'row-in-progress';
      case 'Cancelled':
        return 'row-cancelled';
      default:
        return '';
    }
  }
};

export default AdminOrders;
