import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import OrderConfirmationModal from './components/OrderConfirmationModal';
import AdminOrders from './components/AdminOrders';
import OrderTracking from './components/OrderTracking';
import QuestionsModal from './components/QuestionsModal';
import FooterIcon from './components/FooterIcon';
// import QuestionsModal from './components/QuestionsModal';
// import FooterIcons from './components/FooterIcon';
import Login from './components/Login';
import Chatbot from './components/Chatbot';
import AdminChat from './components/AdminChat';
import CustomOrder from './components/CustomOrder';

import serviceIcon from './assets/serviceIcon.png';
import urlImg from './assets/web.png';
import quantityImg from './assets/quantity.png';
import HighlightsSection from './components/HighlightsSection';
import emailIcon from './assets/email.png';
import couponIcon from './assets/coupon.png';

import InstagramLogo from './assets/platformLogos/Instagram.png';
import FacebookLogo from './assets/platformLogos/Facebook.png';
import YoutubeLogo from './assets/platformLogos/Youtube.png';
import TikTokLogo from './assets/platformLogos/Tiktok.png';
import SpotifyLogo from './assets/platformLogos/Spotify.png';
import XLogo from './assets/platformLogos/X.png';
import LinkedInLogo from './assets/platformLogos/Linkedin.png';
import ReviewsLogo from './assets/platformLogos/Reviews.png';
import AppInstall from './assets/platformLogos/AppInstall.png';
import whyIcon from './assets/why.png';

import currencySymbols from './assets/currencySymbols.json';
import countryToCurrency from './assets/countryToCurrency.json';


const platformLogos = {
  Instagram: InstagramLogo,
  Facebook: FacebookLogo,
  Youtube: YoutubeLogo,
  TikTok: TikTokLogo,
  Spotify: SpotifyLogo,
  X: XLogo,
  LinkedIn: LinkedInLogo,
  'Reviews And Ratings': ReviewsLogo,
  'App Installs': AppInstall,
};

const mongoURI = process.env.REACT_APP_MONGOURI;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEPK);
const api = `${window.location.origin}/api`;

function App() {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [platform, setPlatform] = useState('Instagram');
  const [lastSuccessfulOrderDetails, setLastSuccessfulOrderDetails] = useState(null);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [currencyConversionRates, setCurrencyConversionRates] = useState({});
  const [servicesData, setServicesData] = useState({});
  const [country, setCountry] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [showFaqModal, setShowFaqModal] = useState(false);
  // const [fetchedServiceCodes, setFetchedServiceCodes] = useState(false);

  const handlePlatformSelect = (newPlatform) => {
    if (newPlatform) {
      setPlatform(newPlatform);
      setTimeout(() => {
        const serviceSelect = document.getElementById('serviceSelect');
        if (serviceSelect) {
          serviceSelect.focus();
        }
        window.scrollTo(0, 0);
      }, 0);
    }
  };

  const convertTime = (minutes) => {
    if (minutes >= 1440) {
      const days = Math.floor(minutes / 1440);
      return `${days} day${days > 1 ? 's' : ''}`;
    } else if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  };

  const getCurrencyConversionRates = () => {
    axios.get('https://api.exchangerate-api.com/v4/latest/USD')
      .then(response => {
        response.data.rates.USD = 1;
        setCurrencyConversionRates(response.data.rates);
      })
      .catch(error => {
        console.error('Error fetching currency conversion rates:', error);
      });
  }

  const getCountry = () => {
    axios.get(`${mongoURI}geolocation`)
      .then((response) => {
        const countryCode = response.data.countryCode;
        const userCurrency = countryToCurrency[countryCode];
        setCountry(countryCode);
        setCurrency(userCurrency);
      })
      .catch(error => {
        console.error('Error fetching location:', error);
      });
  }

  // const getServices = () => {
  //   axios.post(api, { task: "services", action: 'services', serviceCodes:servicesData })
  //     .then(response => {
  //       const fetchedServicesData = response.data;
  
  //       // Step 1: Create a mapping of service code to fetched data (excluding packages starting with 'P_')
  //       const servicesMap = {};
  
  //       // Iterate over platforms in fetchedServicesData
  //       Object.keys(fetchedServicesData).forEach(platform => {
  //         const platformServices = fetchedServicesData[platform];
  
  //         // Iterate over each service under the platform
  //         Object.keys(platformServices).forEach(serviceName => {
  //           const service = platformServices[serviceName];
  //           const serviceCode = service.code; // Extract service code if available
  //           // Skip packages (codes starting with 'P_')
  //           if (!serviceCode.startsWith('P_')) {
  //             servicesMap[serviceCode] = {
  //               min: Math.ceil(Number(service.min) * Number(service.rate) > 1 ? Number(service.min) : (1 / Number(service.rate))),
  //               max: Number(service.max),
  //               rate: Number(service.rate),
  //               average_time: Number(service.average_time),
  //               link: service.link || 'Enter Username/Link'
  //             };
  //           }
  //         });
  //       });
  
  //       // Step 2: Iterate over serviceCodesData and merge fetched data (skip packages)
  //       const mergedData = { ...servicesData }; // Make a copy of the serviceCodes JSON
  
  //       Object.keys(mergedData).forEach(platform => {
  //         Object.keys(mergedData[platform]).forEach(serviceName => {
  //           const serviceCode = mergedData[platform][serviceName].code;
  
  //           // Step 3: If it's not a package, merge the fetched data
  //           if (!serviceCode.startsWith('P_') && servicesMap[serviceCode]) {
  //             mergedData[platform][serviceName] = {
  //               ...mergedData[platform][serviceName], // Keep existing package data
  //               ...servicesMap[serviceCode] // Merge in the fetched service data
  //             };
  //           }
  //         });
  //       });
  
  //       // Step 4: Set merged data in your state
  //       console.log('Merged data:', mergedData);
  //       setServicesData(mergedData);
  //     })
  //     .catch(error => {
  //       console.error("Error fetching services:", error);
  //     });
  // };
  
  // useEffect(() => {
  //   if(fetchedServiceCodes)
  //     getServices();
  // }
  // , [fetchedServiceCodes]);

  useEffect(() => {
    const getServicesDataOld = async () => {
      try {
        const response = await axios.get(`${mongoURI}getServicesData`);
        setServicesData(response.data.findResult);
        // setFetchedServiceCodes(true);
      }
      catch (error) {
        console.error('Error fetching services:', error);
      }
    }
    getServicesDataOld();
    
    getCountry();
    getCurrencyConversionRates();
  }, []);

  return (
    <Router>
      <Header mongoURI={mongoURI} setShowFaqModal={setShowFaqModal} handlePlatformSelect={handlePlatformSelect} authToken={authToken} setAuthToken={setAuthToken} />
      <div className='main'>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={
              <>
                <Hero
                  platform={platform}
                  onPlatformChange={handlePlatformSelect}
                  servicesData={servicesData}
                  platformLogos={platformLogos}
                  serviceIcon={serviceIcon}
                  urlImg={urlImg}
                  quantityImg={quantityImg}
                  setLastSuccessfulOrderDetails={setLastSuccessfulOrderDetails}
                  setShowOrderConfirmation={setShowOrderConfirmation}
                  currencyConversionRates={currencyConversionRates}
                  mongoURI={mongoURI}
                  couponIcon={couponIcon}
                  currencySymbols={currencySymbols}
                  country={country}
                  currency={currency}
                  api={api}
                  convertTime={convertTime}
                />
                <Services
                  handlePlatformSelect={handlePlatformSelect}
                  servicesData={servicesData}
                  platformLogos={platformLogos}
                />
                <Testimonials handlePlatformSelect={handlePlatformSelect} platform={platform}/>
                <HighlightsSection />
              </>
            } />
            <Route path="/admin" element={
              !authToken ? (
                <Login setAuthToken={setAuthToken} mongoURI={mongoURI} />
              ) : (
                <AdminOrders servicesData={servicesData} platformLogos={platformLogos} mongoURI={mongoURI} />
              )
            } />
            <Route path="/order-tracking" element={
              <OrderTracking
                servicesData={servicesData}
                platformLogos={platformLogos}
                urlImg={urlImg}
                emailIcon={emailIcon}
                mongoURI={mongoURI}
                convertTime={convertTime}
              />
            } />
            <Route path="/admin-reply" element={
              <AdminChat mongoURI={mongoURI}/>
            } />
            <Route path="/custom-order" element={
              <CustomOrder
                currencyConversionRates={currencyConversionRates}
                mongoURI={mongoURI}
                currencySymbols={currencySymbols}
                country={country}
                currency={currency}
                couponIcon={couponIcon}
                emailIcon={emailIcon}
                whyIcon={whyIcon}
              />
            } />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Elements>
      </div>
      {
        showFaqModal && <QuestionsModal showFaqModal={showFaqModal} setShowFaqModal={setShowFaqModal} />
      }
      {
                  showOrderConfirmation &&
                  <OrderConfirmationModal
                    showOrderConfirmation={showOrderConfirmation}
                    lastSuccessfulOrderDetails={lastSuccessfulOrderDetails}
                    setShowOrderConfirmation={setShowOrderConfirmation}
                    mongoURI={mongoURI}
                    currencySymbols={currencySymbols}
                    setShowFaqModal={setShowFaqModal}
                    convertTime={convertTime}
                    servicesData={servicesData}
                  />
                }
      <Footer setShowFaqModal={setShowFaqModal} handlePlatformSelect={handlePlatformSelect} />
      {/* <Chatbot mongoURI={mongoURI} isChatbotOpen={isChatbotOpen} setIsChatbotOpen={setIsChatbotOpen}/> */}
      <FooterIcon setShowFaqModal={setShowFaqModal} />
    </Router>
  );
}

export default App;
