import React, { useEffect } from 'react';
import { Modal, Button, Accordion } from 'react-bootstrap';
import { FaWhatsapp, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './QuestionsModal.css';

const QuestionsModal = ({ showFaqModal, setShowFaqModal }) => {

  // Prevent background scroll when modal is open
  useEffect(() => {
    if (showFaqModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showFaqModal]);

  return (
    <Modal show={showFaqModal} onHide={() => setShowFaqModal(false)} centered animation={true} className="questions-modal">
      <Modal.Header closeButton aria-label="Close FAQ Modal">
        <Modal.Title>FAQ & Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
              <Accordion.Header>Do you offer custom packages?</Accordion.Header>
              <Accordion.Body>
                Yes, we do! If you need a custom package tailored to your specific needs, please <a href="mailto:mail@buzzboostr.com">Email</a> or contact on <a href="https://wa.me/64225401142">WhatsApp</a>. We’re happy to create a plan that fits your goals perfectly.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>How long does it take to process my order?</Accordion.Header>
              <Accordion.Body>
                The approximated order processing time is shown on the confirmation page and next to the quantity selector. You can also find it on our <Link to='/order-tracking' onClick={() => setShowFaqModal(false)}>Order Tracking Page</Link>. For larger orders, please allow a bit more time.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Is the engagement real?</Accordion.Header>
              <Accordion.Body>
                Yes, 100%! We deliver real and active engagement to grow your social media presence authentically. Our services are safe, secure, and trusted by thousands of users.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>What if my followers or engagement drops?</Accordion.Header>
              <Accordion.Body>
                If your followers or engagement drop, we offer free refills—no questions asked. We’re committed to helping you grow sustainably.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>How can I track my order?</Accordion.Header>
              <Accordion.Body>
                Visit our <Link to="/order-tracking" onClick={() => setShowFaqModal(false)}>Order Tracking Page</Link> and enter your email or the username/URL you used when placing your order.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header>Can I cancel my order?</Accordion.Header>
              <Accordion.Body>
                You can request a cancellation if your order hasn’t been processed yet. Just contact our support team as soon as possible, and we’ll take care of it.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>Is my payment and personal information secure?</Accordion.Header>
              <Accordion.Body>
                Yes. We use industry-standard payment gateways to ensure secure transactions. Your payment details are never stored, and protecting your privacy is our priority.
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>

        <div className="d-flex justify-content-evenly">
        <Button variant="primary" href="mailto:mail@buzzboostr.com" onClick={() => setShowFaqModal(false)} className="button" aria-label="Email">
            Email <FaEnvelope />
          </Button>
          <Button variant="success" href="https://wa.me/64225401142" onClick={() => setShowFaqModal(false)} className="button" aria-label="WhatsApp">
            WhatsApp <FaWhatsapp />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuestionsModal;
